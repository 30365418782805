<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>
      <md-card-content
        :key="formKey"
        class="py-5"
      >
        <div class="px-3">
          <FormSelect
            v-model="form.notification_type"
            label="Notification Type"
            icon="notifications"
            :options="types"
            @change="onTypeChange"
          />
          <NewsSelect
            v-if="form.notification_type === 'news'"
            v-model="form.news_id"
          />
          <RequestTypesSelect
            v-if="form.notification_type === 'request'"
            v-model="form.request_type_id"
            only-student-requests
          />
          <ActivitySelect
            v-if="form.notification_type === 'activity'"
            v-model="form.activity_id"
          />
        </div>

        <hr>

        <div class="px-3">
          <md-tabs
            class="md-primary"
            md-alignment="centered"
            :md-active-tab="activeTab"
            @md-changed="onTabChange"
          >
            <md-tab
              id="student"
              md-label="TO STUDENT"
            />
            <md-tab
              id="group"
              md-label="TO GROUP"
            />
            <md-tab
              id="activity"
              md-label="TO ACTIVITY"
            />
            <md-tab
              v-if="form.notification_type === 'news'"
              id="all"
              md-label="TO ALL STUDENTS"
            />
          </md-tabs>

          <div class="d-flex pt-3">
            <ActivitySelect
              v-if="activeTab === 'activity'"
              class="flex-1"
              show-with-users
              @change="onActivitySelected"
            />
            <ClassroomsSelect
              v-if="activeTab === 'group'"
              class="flex-1"
              @change="findAppStudents"
            />
            <StudentsAutocomplete
              v-if="activeTab === 'student'"
              ref="studentsAutocomplete"
              class="flex-1"
              with-app
              @change="onSelectedStudent"
            />
          </div>

          <div
            v-if="form.students.length"
            class="studentsList pt-4"
          >
            <b>Addressed Students</b>

            <table class="table no-borders text-left">
              <tr>
                <th>Student Number</th>
                <th>Name</th>
                <th />
              </tr>
              <tr
                v-for="(x, index) of form.students"
                :key="x.student_number"
              >
                <td>{{ x.student_number }}</td>
                <td>{{ x.full_name }}</td>
                <td>
                  <md-button
                    class="md-danger md-just-icon md-sm"
                    @click="removeStudent(index)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <hr>

        <div class="px-3">
          <FormText
            v-model="form.title"
            label="Title"
            icon="title"
          />
          <FormTextarea
            v-model="form.body"
            label="Body"
            icon="view_headline"
          />

          <md-button
            class="md-block md-primary"
            :disabled="!formIsValid"
            @click="sendNotification"
          >
            SEND NOTIFICATION
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import {
  ClassroomsSelect, StudentsAutocomplete, NewsSelect, RequestTypesSelect, ActivitySelect,
} from '@/components/Inputs/selects';
import { FormText, FormTextarea, FormSelect } from '@/components/Inputs';

export default {
  components: {
    ClassroomsSelect,
    FormText,
    FormTextarea,
    FormSelect,
    StudentsAutocomplete,
    NewsSelect,
    RequestTypesSelect,
    ActivitySelect,
  },
  data: () => ({
    types: [
      { id: 'alert', name: 'Alert' },
      { id: 'news', name: 'News Alert' },
      { id: 'request', name: 'Student Request' },
      { id: 'activity', name: 'Activity' },
    ],
    form: {
      notification_type: 'alert',
      to_all: false,
      students: [],
      request_type_id: null,
      news_id: null,
      title: null,
      body: null,
    },
    loading: false,
    formKey: Date.now(), // To update form on reset
    activeTab: 'student',
  }),
  computed: {
    formIsValid() {
      let valid = true;

      if (this.form.notification_type === 'news' && !this.form.news_id) valid = false;
      if (this.form.notification_type === 'activity' && !this.form.activity_id) valid = false;
      if (this.form.notification_type === 'request' && !this.form.request_type_id) valid = false;
      if (!this.form.to_all && !this.form.students.length) valid = false;
      if (!this.form.title) valid = false;
      if (!this.form.body) valid = false;

      return valid;
    },
  },
  methods: {
    onTypeChange(e) {
      this.activeTab = 'student';

      if (e.id !== 'news') this.form.news_id = null;
      if (e.id !== 'request') this.form.request_type_id = null;
      if (e.id !== 'activity') this.form.activity_id = null;
    },
    onTabChange(val) {
      this.activeTab = val;
      this.form.students = [];

      this.form.to_all = val === 'all';
    },
    findAppStudents(classroom) {
      if (classroom) {
        this.loading = true;
        this.request(`classrooms/${classroom.classroom_id}/enrolments`, 'get', {
          with_app: true,
          per_page: 100,
        }, ({ data }) => {
          this.fireConfirm(
            !data.total
              ? 'There is no Students with APP in this classroom'
              : 'Adding Classroom Students',
            'Want to clear existent Students in Addressed List?',
            'No',
          )
            .then(() => {
              this.form.students = [];

              if (data.total) {
                this.form.students = data.data.map((x) => ({
                  student_id: x.programme.student.student_id,
                  student_number: x.programme.student.student_number,
                  full_name: x.programme.student.full_name,
                }));
              }
            })
            .catch(() => {
              for (const s of data.data) {
                if (
                  !this.form.students.map((x) => x.student_number)
                    .includes(s.programme.student.student_id)
                ) {
                  this.form.students.push({
                    student_id: s.programme.student.student_id,
                    student_number: s.programme.student.student_number,
                    full_name: s.programme.student.full_name,
                  });
                }
              }
            });
        }, () => {
          this.loading = false;
        });
      }
    },
    onActivitySelected(activity) {
      this.form.students = activity.activity_users.map((x) => ({
        student_id: x.student.student_id,
        student_number: x.student.student_number,
        full_name: x.student.full_name,
      }));
    },
    onSelectedStudent(val) {
      if (!val) return;

      if (!this.form.students.map((x) => x.student_number).includes(val.student_number)) {
        this.form.students.push({
          student_id: val.student_id,
          student_number: val.student_number,
          full_name: val.full_name,
        });
      }

      this.$refs.studentsAutocomplete.reset();
    },
    removeStudent(index) {
      this.form.students.splice(index, 1);
    },
    showAddressedStudents() {
      this.fireModal({
        title: 'Addressed Students',
        html: `<table class="table no-borders text-left">
          <tr>
            <th>Student Number</th>
            <th>Name</th>
          </tr>
          ${this.form.students.map((x) => `<tr>
              <td>${x.student_number}</td>
              <td>${x.full_name}</td>
            </tr>`).join('')}
        </table>`,
      });
    },
    sendNotification() {
      this.loading = true;
      this.request('app/notifications', 'post', this.form, (data) => {
        this.fireSuccess(data);
        this.resetForm();
        this.$emit('onSave');
      }, () => {
        this.loading = false;
      });
    },
    resetForm() {
      this.form = {
        notification_type: 'alert',
        students: [],
        news_id: null,
        classroom_id: null,
        title: null,
        body: null,
      };
      this.formKey = Date.now();
    },
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .md-tabs .md-tabs-navigation {
    margin: 0;
    padding: 0 !important;
  }
</style>
